export const menuItems = [
  {
    id: 1,
    label: "menuitems.menu.text",
    isTitle: true,
    show: "alwaysActive",
  },
  {
    id: 1001,
    label: "menuitems.dashboards.list.default",
    icon: "bx-home-circle",
    link: "/",
    show: "alwaysActive",
  },
  {
    id: 1002,
    label: "menuitems.email_activity.text",
    icon: "bx-envelope",
    link: "/email/activity",
    show: "modulo actividad de email",
  },
  {
    id: 1003,
    label: "menuitems.sendgrid_activity.text",
    icon: "bx-envelope",
    link: "/sendgrid/activity",
    show: "modulo actividad de sendgrid",
  },
];
